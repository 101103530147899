<template>
  <div :class="b()">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-form v-model="valid" ref="form">
              <v-container class="px-5">
                <h2 class="mb-3 mt-2">Оставить отзыв</h2>
                <div class="d-flex flex-row">
                  <div class="d-flex align-center">
                    <h4>Ваша оценка:</h4>
                  </div>
                  <div>
                    <v-rating
                      :empty-icon="icons.mdiStarOutline"
                      :full-icon="icons.mdiStar"
                      hover
                      v-model="rating"
                      size="45"
                      length="5"
                      background-color="primary "
                    ></v-rating>
                  </div>
                </div>
                <v-row class="pt-5">
                  <v-col cols="12" sm="6">
                    <h3 class="mb-3">Имя *</h3>
                    <v-text-field
                      class="form_control"
                      label="Имя"
                      v-model="name"
                      outlined
                      clearable
                      dense
                      :single-line="true"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h3 class="mb-3">Телефон</h3>
                    <v-text-field
                      class="form_control"
                      label="(999) 999-9999"
                      v-model="phone"
                      outlined
                      prefix="+7"
                      clearable
                      :single-line="true"
                      :rules="phoneRules"
                      v-mask="'(###) ###-####'"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8" v-if="doctorId">
                    <v-select
                      item-text="name"
                      item-value="id"
                      :items="objDoctorDepartment"
                      v-model="doctorDepartmentId"
                      label="Выберите клинику"
                      outlined
                      flat
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <h3 class="mb-3">Комментарий *</h3>
                    <v-textarea
                      v-model="comment"
                      counter
                      maxlength="120"
                      full-width
                      single-line
                      outlined
                      dense
                    ></v-textarea>
                    <span> * Обязательное поле</span>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      class="mt-0 pt-0"
                      v-model="personalData"
                      :off-icon="icons.mdiCheckboxBlankOutline"
                      :on-icon="icons.mdiCheckboxMarked"
                      label="Согласие на обработку персональных данных"
                      slot="append"
                    ></v-checkbox>

                    <v-scroll-y-transition>
                      <a
                        target="_blank"
                        href="https://old.medveddoc.com/docs/personal_data_protection.pdf"
                        class="pl-8"
                        v-if="personalData"
                        >Положение о защите персональных данных
                      </a>
                    </v-scroll-y-transition>
                  </v-col>
                  <v-col class="mb-3">
<!--                    <vue-recaptcha-->
<!--                            ref="recaptcha"-->
<!--                            :sitekey="sitekey"-->
<!--                            @expired="onCaptchaExpired"-->
<!--                    />-->
                  </v-col>
                  <v-col class="d-flex align-center mb-3 justify-end" >
                    <v-btn
                      color="success"
                      @click="sendFeedback"
                      :disabled="disabled"
                      outlined
                    >
                      Отправить отзыв
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            v-for="(item, index) in objFeedback"
            :key="index"
            class="mb-5 feedback-card"
            outlined
            shaped
            color="toolbar"
          >
            <v-card-title class="headline">
              <v-icon left>
                {{ icons.mdiCommentQuote }}
              </v-icon>
              <v-spacer />
              <div class="d-flex justify-start align-start">
                <v-rating
                  :empty-icon="icons.mdiStarOutline"
                  :full-icon="icons.mdiStar"
                  hover
                  :value="item.rating"
                  readonly
                  size="40"
                  length="5"
                  background-color="primary"
                ></v-rating>
              </div>
            </v-card-title>
            <v-card-text v-text="item.text" class="headline font-weight-bold" />
            <v-card-text v-if="doctorId"
              >Прием был осуществлен в клинике
              {{ item.doctor_department_name }}</v-card-text
            >
            <v-card-actions>
              <v-list-item>
                <v-icon class="mr-1">
                  {{ icons.mdiPhone }}
                </v-icon>
                <span class="subheading mr-2">+7 {{ item.phone }}</span>
                <span class="mr-1">·</span>

                <v-row align="center" justify="end">
                  <v-icon class="mr-1">
                    {{ icons.mdiCalendar }}
                  </v-icon>
                  <span class="subheading mr-2">{{ item.created_at }}</span>
                  <span class="mr-1">·</span>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as names from "store/names";
// import VueRecaptcha from "vue-recaptcha";
import { mapActions, mapGetters } from "vuex";
import {
  mdiArrowLeft,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiMenuDown,
  mdiStarOutline,
  mdiStar,
  mdiCommentQuote,
  mdiCalendar,
  mdiPhone,
} from "@mdi/js";

export default {
  name: "CardFeedback",
  props: ["departmentId", "doctorId", "type"],
  // components: { VueRecaptcha },
  data: () => ({
    name: "",
    phone: "",
    comment: "",
    disabled: false,
    personalData: false,
    rating: 5,
    // sitekey: process.env.VUE_APP_SITEKEY,
    icons: {
      mdiCheckboxBlankOutline,
      mdiCheckboxMarked,
      mdiMenuDown,
      mdiArrowLeft,
      mdiStarOutline,
      mdiStar,
      mdiCommentQuote,
      mdiCalendar,
      mdiPhone,
    },
    nameRules: [
      (value) => !!value || "Введите имя",
      (value) => value.length <= 50 || "Максимальная длина строки 50 символов",
      (value) =>
        /^[\sa-zA-Zа-яёА-ЯЁ-]+$/.test(value) ||
        "Имя может состоять только из букв, пробелов и тире",
    ],
    phoneRules: [
      (value) => !!value || "Введите телефон",
      (value) =>
        /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10}(\s*)?$/.test(value) ||
        "Введите корректный телефон",
    ],
    doctorDepartmentId: [],
  }),
  computed: {
    ...mapGetters({
      docId: names.DOCTOR_ID,
      objDoctorDepartment: names.OBJ_DOCTOR_CARD_DEPARTMENT,
      objFeedback: names.OBJ_FEEDBACK,
    }),
    color() {
      switch (this.rating) {
        case 0:
          return "blue-grey";
        case 1:
          return "teal";
        case 2:
          return "brown";
        case 3:
          return "indigo";
        default:
          return "blue-grey";
      }
    },
    correctData() {
      return function (str) {
        let res;
        res = str.split(" ");
        return res[0];
      };
    },

    apiAddress() {
      return names.API_ADDRESS;
    },
  },
  created() {
    this.getFeedback({
      type: this.type,
      doctorId: this.doctorId,
      departmentId: this.departmentId,
    });
  },
  methods: {
    ...mapActions({
      getFeedback: names.ACTION_GET_OBJ_FEEDBACK,
      addFeedback: names.ACTION_SEND_OBJ_FEEDBACK,
    }),

    // onCaptchaExpired () {
    //   this.$refs.recaptcha.reset()
    // },

    async sendFeedback() {
      if (!this.name || this.name === " ") {
        return this.$notifier.showMessage("Введите имя", "warning");
      }

      if (!this.comment) {
        return this.$notifier.showMessage(
          "Поле комментария не должно быть пустым!",
          "warning"
        );
      }

      if (!this.personalData) {
        return this.$notifier.showMessage(
          "Вы должны согласиться на обработку персональных данных",
          "warning"
        );
      }

      // // eslint-disable-next-line no-undef
      // this.recaptcha_token = grecaptcha.getResponse();

      // if (!this.recaptcha_token) {
      //   return this.$notifier.showMessage(
      //           "Подтвердите, что Вы не робот!",
      //           "warning"
      //   );
      // }

      this.disabled = true;

      let orderPhone = this.phone
        .replace(/^\+[7]+/g, "")
        .match(/\d+/g)
        .join("");

      let response = await this.addFeedback({
        type: this.type,
        text: this.comment,
        doctor_id: this.doctorId,
        department_id: this.departmentId,
        rating: this.rating,
        email: null,
        phone: orderPhone,
        name: this.name,
        doctor_department_id: this.doctorDepartmentId,
        // recaptcha_token: this.recaptcha_token,
      });

      if (response === "success") {
        this.name = "";
        this.phone = "";
        this.comment = "";
        this.personalData = false;
        this.disabled = false;
        this.doctorDepartmentId = [];
        return (
            // this.onCaptchaExpired(),
            this.$refs.form.reset(),
            this.$notifier.showMessage(
            "Ваш отзыв успешно отправлен! Он будет опубликован после модерации.",
            "success"
          )
        );
      } else {
        this.disabled = false;
        return (
            // this.onCaptchaExpired(),
            this.$refs.form.reset(),
            this.$notifier.showMessage(
            "Что-то пошло не так, пожалуйста, попробуйте отправить отзыв снова!",
            "error"
          )
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/_helpers.scss";

.feedback-card {
  min-height: 100px;

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    span {
      font-size: 0.9rem;
    }
  }
  .comment-header {
    background-color: $primary-color;
  }
}

.feedback-text {
  padding: 20px 0;
}
</style>
