<template>
  <v-dialog width="800px" v-model="addressMap" @click:outside="destroyMap">
    <v-card class="map-container mx-auto">
      <v-card-title class="d-flex flex-column align-start"
        ><h2>{{ title }}</h2>
        <span v-if="address !== undefined">{{ address }}</span></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text v-if="!mapError" class="map-wrapper">
        <div v-show="readyMap">
          <div id="map" class="map-body"></div>
        </div>
        <v-layout align-center justify-center class="mt-10">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-layout>
      </v-card-text>
      <v-card-text v-if="mapError" class="map-wrapper">
        Что-то пошло не так, пожалуста, попробуйте перезагрузить страницу.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="destroyMap">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadYmap } from "vue-yandex-maps";
import * as names from "store/names";

let myMap;
export default {
  props: ["address", "title", "coordinates"],
  data: () => ({
    loading: false,
    readyMap: false,
    mapError: false,
  }),
  name: "MapModal",
  components: {},
  computed: {
    ...mapGetters({
      addressMap: names.ADDRESS_DIALOG,
    }),
  },
  async mounted() {
    this.loading = true;
    setTimeout(() => this.mapLoading(), 3000);

    const settings = {
      apiKey: "ffce6b87-86ce-4cfd-90cf-2ee8c1adb403",
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    };

    await loadYmap(settings, { debug: true });
    // Как только будет загружен API и готов DOM, выполняем инициализацию
    window.ymaps.ready(this.initMap);
  },
  methods: {
    ...mapActions({
      openDialog: names.ACTION_SAVE_ADDRESS_DIALOG,
    }),
    mapLoading() {
      this.loading = false;
      this.readyMap = true;
    },

    initMap() {
      myMap = new window.ymaps.Map(
            "map",
            {
              center: [48.7193900, 44.5018300  ], // Центрируем камеру по нашим координатам
              zoom: 10,
            },
            {
              searchControlProvider: "yandex#search",
            }
          );

      let blueCollection = new window.ymaps.GeoObjectCollection(null, {
        preset: 'islands#blueIcon',
      });

          for (let i = 0; i < this.coordinates.length; i++) {
            let m = this.coordinates[i]
        blueCollection.add(new window.ymaps.Placemark(this.coordinates[i], {
          balloonContent: 'клиника' + ' ' + m[2]
        }));
      }
      myMap.geoObjects.add(blueCollection);
      //Старый код для установки единственной метки, использует геокодирование, нужно внедрить для проставление коллекции меток
      // Ищем координаты по адресу
      // let coordinates = window.ymaps.geocode(this.address);
      // coordinates.then(
      //   function (res) {
      //     let coordinates = res.geoObjects.get(0).geometry.getCoordinates();
      //     myMap = new window.ymaps.Map(
      //       "map",
      //       {
      //         center: coordinates, // Центрируем камеру по нашим координатам
      //         zoom: 15,
      //       },
      //       {
      //         searchControlProvider: "yandex#search",
      //       }
      //     );
      //     let addressPoint = new window.ymaps.GeoObject({
      //       geometry: {
      //         type: "Point",
      //         coordinates: coordinates,
      //       },
      //     });
      //     myMap.geoObjects.add(addressPoint);
      //   },
      //   function (err) {
      //     /* TODO: Тут не вызывается $notifier, подумай как можно вывести ошибку. */
      //     // Обработка ошибки.
      //     console.log(err);
      //     this.mapError = true;
      //   }
      // );
    },
    destroyMap() {
      myMap.destroy();
      this.openDialog(false);
    },
  },
};
</script>

<style scoped lang="scss">
.map-container {
  max-width: 800px;
  max-height: 700px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.map-wrapper {
  min-height: 400px;
}
.map-body {
  max-width: 800px;
  height: 400px;
  .span {
    font-size: 1rem;
  }
}
</style>
